import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DisplayAvatar from '../../components/DisplayAvatar';
import { MdAccountCircle } from 'react-icons/md';
import './Contact.css';
import { CURRENT_CHAT_PATIENT } from '../../constants/notificationsConstants';

const Contact = props => {
  const { conversation } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { unreadMessages } = useSelector(state => state.notificationsChat);

  const openChat = () => {
    dispatch({ type: CURRENT_CHAT_PATIENT, payload: conversation.other_user });
    navigate(`/conversations/${conversation.name}`);
  };
  const checkUserUnredMessages = unreadMessages.filter(({ from_user__uuid }) =>
    conversation.other_user?.uuid.includes(from_user__uuid)
  );
  console.log(unreadMessages, checkUserUnredMessages[0]?.unread, conversation.other_user?.uuid);

  const user = {};
  user.minimized_photo_url = conversation.other_user?.profile_photo;

  return (
    <div className="contact" onClick={() => openChat()}>
      <div className="contact__avatar">
        {conversation.other_user?.profile_photo ? (
          <DisplayAvatar user={user} />
        ) : (
          <MdAccountCircle color="#e6e6e6" size="40px" />
        )}
      </div>

      <div className="contact__text">
        <h2> {conversation.other_user?.name} </h2>
        <p> {conversation.last_message?.content} </p>
      </div>

      <div className="contact__unredcount">
        {checkUserUnredMessages[0] && (
          <span> {checkUserUnredMessages[0] ? checkUserUnredMessages[0]?.unread : null} </span>
        )}
      </div>
    </div>
  );
};

export default Contact;
