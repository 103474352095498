import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Container } from 'react-bootstrap';
import './registration.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Demografics from './Demografics';
import ClinicalFolderCard from './ClinicalFolderCard';
import PresentationsCard from './PresentationsCard';
import AnamnesisTab from './AnamnesisTab';
import DiagnosisTab from './DiagnosisTab';
import TreatmentsTab from './TreatmentsTab';
import VisitsTab from './VisitsTab';
import RecordsTab from './RecordsTab';
import RemoteRecordsTab from './RemoteRecordsTab';
import LegalDocs from './LegalDocs';

import { useParams } from 'react-router-dom';

import { listLatestRecords } from '../../actions/visitsActions';
import { getPatientProfile } from '../../actions/patientsActions';
import { RECORDS_DETAILS_DELETE_SUCCESS, VISITS_LIST_RESET, VISIT_CREATE_RESET } from '../../constants/visitsConstants';
import { getDiagnosis } from '../../actions/diagnosisActions';
import { getTreatment } from '../../actions/treatmentActions';
import { getAnamnesisList, getTreatmentNotesList } from '../../actions/notesActions';
import { RECORDS_DETAILS_SUCCESS } from '../../constants/visitsConstants';

// todo: core-ui CSS import
import '../../scss/coreUI.scss';

function NewPatientScreen() {
  const match = useParams();
  const dispatch = useDispatch();
  const patientId = match.id;

  const [visitId, setVisitId] = useState('');
  const [visitType, setVisitType] = useState('Doctor');
  const [editImageProgress, setEditImageProgress] = useState(0, '');

  const [step, setStep] = useState('visits');
  const [updateProfile, setUpdateProfile] = useState(false);

  const [recordUploadSuccess, setRecordUploadSuccess] = useState(false);

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  const patientProfile = useSelector(state => state.patientProfile);
  const { error: errorPP, loading: loadingPP, success: successPP, patient } = patientProfile;

  const patientUpdate = useSelector(state => state.patientUpdate);
  const { error: errorPU, loading: loadingPU, success: successPU } = patientUpdate;

  const diagnosisInfo = useSelector(state => state.diagnosisInfo);
  const { error: errorD, success: successD, diagnosis: diagnosis } = diagnosisInfo;

  const treatmentInfo = useSelector(state => state.treatmentInfo);
  const { error: errorT, success: successT, treatment } = treatmentInfo;

  const { photosVisits, loading: loadingRemotePhotos } = useSelector(({ remoteVisitsList }) => remoteVisitsList);

  const visitCreate = useSelector(state => state.visitCreate);
  const { error: errorVAdd, loading: loadingVadd, success: successVadd } = visitCreate;

  let remote = true;

  useEffect(() => {
    dispatch(getPatientProfile(patientId));
    dispatch(getDiagnosis(patientId));
    dispatch(getTreatment(patientId));
    dispatch(listLatestRecords(patientId));
    dispatch(getAnamnesisList(patientId));
    dispatch(getTreatmentNotesList(patientId));
  }, [dispatch]);

  useEffect(() => {
    if (updateProfile && successPU) {
      dispatch(getPatientProfile(patient.id));
      setUpdateProfile(false);
      console.log(successPU);
    }
  }, [dispatch, updateProfile, successPU]);

  useEffect(() => {
    if (successVadd) {
      dispatch(listLatestRecords(patientId));
      dispatch({ type: VISIT_CREATE_RESET });
    }
  }, [dispatch, successVadd]);

  const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const editImg = async (loadedFile, recordType) => {
    let formData = new FormData();
    formData.append('record_type', recordType);
    let size_limit = 40 * 10 ** 6;

    if (loadedFile.size > size_limit) {
      alert('file is too big! /n File limit is 40Mb.');
      return;
    }

    if (loadedFile?.editedBlob) {
      let file = DataURIToBlob(loadedFile.uri);
      formData.append('record', file, loadedFile.type);
    } else {
      // loadedFile.type = 'model/stl'
      formData.append('record', loadedFile, loadedFile.name);
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.access}`,
        },
        transformRequest: formData => formData,
        onUploadProgress: progressEvent => {
          let percentComplete = progressEvent.loaded / progressEvent.total;
          percentComplete = parseInt(percentComplete * 100);
          setEditImageProgress({ percentComplete: percentComplete, recordType: recordType });
          //console.log(percentComplete)
          if (percentComplete === 100) {
            setTimeout(() => {
              setEditImageProgress(0);
              dispatch(getPatientProfile(patientId));
            }, 500);
          }
        },
      };

      const { data } = await axios.put(
        `/api/patients/profile/${patientId}/visits/${visitId}/upload/`,
        formData,
        config
      );
      dispatch({
        type: RECORDS_DETAILS_SUCCESS,
        payload: data.data,
      });
      setRecordUploadSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImg = async (type, id = 0) => {
    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.put(
        `/api/patients/profile/${patientId}/visits/${visitId}/remove/`,
        { record_type: type, id },
        config
      );
      dispatch({
        type: RECORDS_DETAILS_DELETE_SUCCESS,
        payload: type,
      });
      setRecordUploadSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className="px-1 mx-1" fluid>
      <div>
        <Row className="py-2">
          <Col sm={12} md={4} lg={4} className=" ">
            <Card className="background-default shadow radius" style={{ height: '100%' }}>
              <Scrollbars
                renderTrackHorizontal={props => (
                  <div {...props} style={{ display: 'none' }} className="track-horizontal" />
                )}
                disableHorizontalScrolling
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: '100%', margin: 0, padding: 0, width: '100%' }}
              >
                <Card.Body className="justify-content-center">
                  {patient?.id && <Demografics patient={patient} setUpdateProfile={setUpdateProfile} />}
                  {patient?.id && <LegalDocs patient={patient} setUpdateProfile={setUpdateProfile} />}
                  {patient?.id && <ClinicalFolderCard patient={patient} step={step} setStep={setStep} />}
                  {patient?.id && <PresentationsCard patientId={patientId} step={step} setStep={setStep} />}
                </Card.Body>
              </Scrollbars>
            </Card>
          </Col>

          <Col sm={12} md={4} lg={4}>
            <Card className="background-default shadow radius" style={{ height: '100%' }}>
              <Scrollbars
                renderTrackHorizontal={props => (
                  <div {...props} style={{ display: 'none' }} className="track-horizontal" />
                )}
                disableHorizontalScrolling
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: '100%', margin: 0, padding: 0, width: '100%' }}
              >
                <Card.Body className="justify-content-center ">
                  {/* <DiagnosisTab diagnosis={diagnosis} setDiagnosis={setDiagnosis} /> */}
                  {step === 'anamnesis' && <AnamnesisTab patientId={patientId} />}
                  {step === 'diagnosis' && <DiagnosisTab patientId={patientId} diagnosis={diagnosis} />}
                  {step === 'treatment' && (
                    <TreatmentsTab patient={patient} treatment={treatment} patientId={patientId} />
                  )}
                  {step === 'visits' && (
                    <VisitsTab setVisitType={setVisitType} visitId={visitId} setVisitId={setVisitId} />
                  )}
                </Card.Body>
              </Scrollbars>
            </Card>
          </Col>

          <Col sm={12} md={4} lg={4}>
            <Card className="background-default shadow radius">
              <Card.Body
                className="justify-content-center"
                style={{ maxHeight: '80vh', minHeight: '80vh', overflow: 'auto' }}
              >
                {visitType !== 'Remote' && (
                  <RecordsTab
                    visitId={visitId}
                    editImageProgress={editImageProgress}
                    editImg={editImg}
                    deleteImg={deleteImg}
                  />
                )}
                {photosVisits && visitType === 'Remote' && (
                  <RemoteRecordsTab visitId={visitId} editImageProgress={editImageProgress} />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default NewPatientScreen;
