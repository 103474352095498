import React, { useState } from 'react';
import axios from 'axios';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import RecordField from '../../components/ImageComponent/RecordField';
import fileDownload from 'js-file-download';
import UploadButton from './component/UploadButton';

function RecordsTab(props) {
  const { t } = useTranslation('contentTrs');
  const { t: tabDiagnosisT } = useTranslation('tabDiagnosis');
  const { deleteImg, editImg, readOnly = false, editImageProgress } = props;
  const { userInfo } = useSelector(state => state.userLogin);

  const {
    error: errorPP,
    loading: loadingPP,
    success: successPP,
    patient,
  } = useSelector(state => state.patientProfile);

  const { error: errorR, loading: loadingR, success: successR, records } = useSelector(state => state.recordsList);

  const downloadImg = async recordType => {
    console.log(patient?.id, records?.visit_id, recordType);

    try {
      const config = {
        headers: {
          // 'Content-type': 'image/jpeg',
          Authorization: `Bearer ${userInfo.access}`,
        },
        responseType: 'blob',
      };

      const res = await axios.get(
        `/api/patients/profile/${patient?.id}/visits/${records?.visit_id}/download/${recordType}`,
        config
      );
      console.log(res.data);

      let filename = 'visit' + records?.visit_num + '_' + recordType + '.' + res.data.type.split('/')[1];
      fileDownload(res.data, filename);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Row>
        <h5>{t('recordTabCol.title')}</h5>
      </Row>

      <Row>
        <h6>{t('recordTabCol.extra_oralRecords')}</h6>
      </Row>
      <Row className="justify-content-around" sm={5} md={5} lg={5} xl={5}>
        {records &&
          ['front_smile', 'front_relaxed', 'lateral_smile', 'lateral_relaxed'].map(type => {
            return (
              <div
                className="imageRectangle shadow my-1 relative p-0"
                //style={error && index === 0 ? {border: '2px solid red'} : {}}
                key={Math.random() * 100}
              >
                <RecordField
                  image={{
                    photo_url: records[type],
                    minimized_photo_url: records?.minimized_records && records?.minimized_records[type], //record //dentistData.photo_url
                  }}
                  editImageProgress={editImageProgress}
                  editImg={editImg}
                  recordType={type}
                  readOnly={readOnly}
                  deleteImg={deleteImg}
                  downloadImg={downloadImg}
                />
              </div>
            );
          })}
      </Row>

      <Row>
        <h6>{t('recordTabCol.intra_oralRecords')}</h6>
      </Row>
      <Row className="justify-content-around" sm={12} md={6} lg={6} xl={6}>
        {records &&
          [
            'intraoral_occlucion_front',
            'intraoral_occlucion_ovj',
            'intraoral_occlucion_left',
            'intraoral_occlucion_right',
            'intraoral_arch_superior',
            'intraoral_arch_inferior',
          ].map(type => {
            const minimized_photo_url = records?.minimized_records && records?.minimized_records[type];
            return (
              <div
                className={`${records[type] ? 'imageRectangle-with-photo' : 'imageRectangle'} shadow my-1 relative p-0`}
                key={Math.random() * 100}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <RecordField
                  image={{
                    photo_url: records[type],
                    minimized_photo_url: minimized_photo_url,
                  }}
                  editImageProgress={editImageProgress}
                  editImg={editImg}
                  recordType={type}
                  readOnly={readOnly}
                  deleteImg={deleteImg}
                  downloadImg={downloadImg}
                />
                {<h6> {!minimized_photo_url && type.includes('left') && tabDiagnosisT('left')} </h6>}
                {<h6> {!minimized_photo_url && type.includes('right') && tabDiagnosisT('right')} </h6>}
              </div>
            );
          })}
      </Row>

      <Row>
        <h6>{t('recordTabCol.x_rays')}</h6>
      </Row>
      <Row className="justify-content-around" sm={5} md={5} lg={5} xl={5}>
        {records &&
          ['xray_tele', 'xray_opt'].map(type => {
            return (
              <div
                className="imageRectangle shadow my-1 relative p-0"
                //style={error && index === 0 ? {border: '2px solid red'} : {}}
                key={Math.random() * 100}
              >
                <RecordField
                  image={{
                    photo_url: records[type],
                    minimized_photo_url: records?.minimized_records && records?.minimized_records[type], //record //dentistData.photo_url
                  }}
                  editImageProgress={editImageProgress}
                  editImg={editImg}
                  recordType={type}
                  readOnly={readOnly}
                  deleteImg={deleteImg}
                  downloadImg={downloadImg}
                />
              </div>
            );
          })}
      </Row>

      <Row>
        <h6>
          {t('recordTabCol.impressions.title')} {'(stl, jpg)'}
        </h6>
      </Row>
      <Row className="justify-content-around" sm={5} md={5} lg={5} xl={5}>
        {records &&
          ['impression_upper_arch', 'impression_lower_arch', 'impression_occlusion'].map(type => {
            const impression_name = {
              impression_upper_arch: t('recordTabCol.impressions.upper'),
              impression_lower_arch: t('recordTabCol.impressions.lower'),
              impression_occlusion: t('recordTabCol.impressions.occlusion'),
            };
            return (
              <div
                className="imageRectangle shadow my-1 relative p-0"
                //style={error && index === 0 ? {border: '2px solid red'} : {}}
                key={Math.random() * 100}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <RecordField
                  image={{
                    photo_url: records[type],
                    minimized_photo_url: records?.minimized_records && records?.minimized_records[type], //record //dentistData.photo_url
                  }}
                  editImageProgress={editImageProgress}
                  editImg={editImg}
                  recordType={type}
                  readOnly={readOnly}
                  deleteImg={deleteImg}
                  downloadImg={downloadImg}
                />
                <h6>{impression_name[type]}</h6>
              </div>
            );
          })}
      </Row>
      <Row>
        <h6>
          {t('recordTabCol.additionalRecords')} {t('recordTabCol.imageVideo')}
        </h6>
      </Row>
      <Row className="justify-content-around" sm={5} md={5} lg={5} xl={5}>
        {records?.other_records?.length > 0 &&
          records.other_records.map((record, index) => {
            return (
              <div className="imageRectangle shadow my-1 relative p-0 flex flex-col" key={index}>
                <RecordField
                  image={{
                    photo_url: record.file,
                    minimized_photo_url: record?.minimized_file && record?.minimized_file,
                  }}
                  videoUrl={record?.file_type && record?.file_type === 'Video' ? record.file : ''}
                  isNonUploadableField={true}
                  recordType={'other-records'}
                  readOnly={readOnly}
                  deleteImg={() => {
                    deleteImg('other-records', record.id);
                  }}
                  downloadImg={downloadImg}
                />
              </div>
            );
          })}
        <div>
          <UploadButton patientId={patient?.id} visitId={records?.visit_id} />
        </div>
      </Row>
    </div>
  );
}

export default RecordsTab;
